import React, { useEffect, useState } from 'react';
import { getResources } from 'services/api/namespaceService';
import { toast } from 'react-toastify';
import Loading from 'views/loading/Loading';

const Resources = ({ organizationId, tenantId, namespaceId }) => {
  const [resources, setResources] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchResources = async () => {
      try {
        setLoading(true);
        const resourceData = await getResources(
          organizationId,
          tenantId,
          namespaceId,
        );
        setResources(resourceData);
        toast.success('Resources fetched successfully.', {
          autoClose: 3000,
          position: 'top-center',
        });
      } catch (err) {
        console.error('Failed to fetch resources:', err);
        toast.error('Failed to fetch resources.', {
          autoClose: 3000,
          position: 'top-center',
        });
      } finally {
        setLoading(false);
      }
    };

    if (namespaceId) {
      fetchResources();
    }
  }, [organizationId, tenantId, namespaceId]);

  if (loading) return <Loading />;
  if (!resources) return null;

  return (
    <div className="bg-white shadow rounded-lg p-4">
      <h4 className="text-md font-semibold">Pods</h4>
      {resources.pods.length > 0 ? (
        <ul>
          {resources.pods.map(pod => (
            <li key={pod.identity} className="py-2">
              {pod.name}
            </li>
          ))}
        </ul>
      ) : (
        <div>No pods available.</div>
      )}

      <h4 className="text-md font-semibold mt-4">Deployments</h4>
      {resources.deployments.length > 0 ? (
        <ul>
          {resources.deployments.map(deployment => (
            <li key={deployment.identity} className="py-2">
              {deployment.name}
            </li>
          ))}
        </ul>
      ) : (
        <div>No deployments available.</div>
      )}

      <h4 className="text-md font-semibold mt-4">Services</h4>
      {resources.services.length > 0 ? (
        <ul>
          {resources.services.map(service => (
            <li key={service.identity} className="py-2">
              {service.name}
            </li>
          ))}
        </ul>
      ) : (
        <div>No services available.</div>
      )}
    </div>
  );
};

export default Resources;
