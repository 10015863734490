import apiClient from "../apiService";


export const createTenant = async (tenant, organizationId) => {
    try {
        const response = await apiClient.post('/v1/tenants', tenant, {
            headers: {
                'X-Organisation-Identity': organizationId,
            },
        });
        return response;
    } catch (err) {
        console.error('Error creating tenant:', err.response ? err.response.data : err.message);
        throw err;
    }
}

export const getTenants = async (organisationId) => {
    try {
        const response = await apiClient.get('/v1/tenants', {
            headers: {
                'X-Organisation-Identity': organisationId,
            },
        });
        return response.data;
    } catch (err) {
        console.error('Error getting tenants:', err);
        return null;
    }
}

export const getTenant = async (tenantId, organisationId) => {
    try {
        const response = await apiClient.get(`/v1/tenants/${tenantId}`, {
            headers: {
                'X-Organisation-Identity': organisationId,
            },
        });
        return response;
    } catch (err) {
        console.error('Error getting tenant:', err);
        return null;
    }
}

export const updateTenant = async (tenant, tenantId, organisationId) => {
    try {
        const response = await apiClient.put(`/v1/tenants/${tenantId}`, tenant, {
            headers: {
                'X-Organisation-Identity': organisationId,
            },
        });
        return response;
    } catch (err) {
        console.error('Error updating tenant:', err.response ? err.response.data : err.message);
        throw err;
    }
}

export const deleteTenant = async (tenantId, organisationId) => {
    try {
        await apiClient.delete(`/v1/tenants/${tenantId}`, {
            headers: {
                'X-Organisation-Identity': organisationId,
            },
        });
    } catch (err) {
        console.error('Error deleting tenant:', err);
        throw err;
    }
}

// Kubeconfig API

export const getKubeconfig = async (tenantId, organisationId) => {
    try {
        const response = await apiClient.post(`/v1/tenants/${tenantId}/kubeconfig`, null, {
            headers: {
                'X-Organisation-Identity': organisationId,
            },
        });
        return response;
    } catch (err) {
        console.error('Error getting kubeconfig:', err);
        return null;
    }
}

// Yaml API

export const sendYaml = async (tenantId, organisationId, yaml) => {
    try {
        const response = await apiClient.post(`/v1/tenants/${tenantId}/yaml`, yaml, {
            headers: {
                'X-Organisation-Identity': organisationId,
            },
        });
        return response;
    } catch (err) {
        console.error('Error sending yaml:', err);
        return null;
    }
}