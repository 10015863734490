import React, { useState, useEffect, useRef } from 'react';
import TenantStatus from 'utilities/TenantStatus';
import { formatDateTime } from 'utilities/formatDateTime';
import { FaEllipsisV } from 'react-icons/fa';
import { deleteTenant } from 'services/api/tenantService';
import { toast } from 'react-toastify';

const TenantSummary = ({ tenant }) => {
  const [openMenu, setOpenMenu] = useState(null);
  const menuRef = useRef(null);

  const toggleMenu = namespaceId => {
    setOpenMenu(openMenu === namespaceId ? null : namespaceId);
  };

  useEffect(() => {
    const handleClickOutside = event => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setOpenMenu(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const undeployTenant = async () => {
    const tenantId = tenant.identity;
    const organizationId = tenant.organisation.identity;
    try {
      await deleteTenant(tenantId, organizationId);
      toast.success('Tenant deleted successfully.', {
        autoClose: 3000,
        position: 'top-center',
      });
    } catch (err) {
      toast.error('Error deleting tenant.', {
        autoClose: 3000,
        position: 'top-center',
      });
      console.error(`Failed to delete tenant: ${err.message}`);
    }
  };

  // const saveTenant = async () => {
  //   try {
  //     const response = await updateTenant(tenant, tenantId, organizationId);
  //     if (response && response.data) {
  //       setTenant(response.data);
  //       setError('');
  //     }
  //   } catch (err) {
  //     setError(`Failed to update tenant: ${err.message}`);
  //   }
  // };

  if (!tenant) {
    return <p>No tenant data available.</p>;
  }

  return (
    <div className="max-w-8xl mx-auto mt-3">
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-400 dark:bg-navy-700">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase">
                Tenant Name
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase">
                Status
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase">
                Status Message
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase">
                Last transition
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase"></th>
            </tr>
          </thead>
          <tbody className="bg-white hover:bg-gray-50 dark:bg-navy-700 dark:hover:bg-navy-600">
            <tr>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-white">
                {tenant.name}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-white">
                <TenantStatus status={tenant.status} />
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-white">
                {tenant.statusMessage}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-white">
                {formatDateTime(tenant.lastStatusTransitioned_at)}
              </td>
              <td className="px-6 py-4 whitespace-nowrap dark:text-white">
                <button
                  onClick={() => toggleMenu(tenant.identity)}
                  className="text-gray-500 hover:text-gray-700 ml-2"
                >
                  <FaEllipsisV />
                </button>
                {openMenu === tenant.identity && (
                  <div
                    ref={menuRef}
                    className="absolute right-4 mt-2 w-48 bg-white shadow-lg rounded-lg dark:bg-navy-700"
                  >
                    <ul className="py-1">
                      <li>
                        <a
                          href="#edit"
                          className="block px-6 py-2 text-sm text-gray-700 hover:bg-gray-50 dark:hover:bg-navy-600 dark:text-gray-300"
                        >
                          Edit
                        </a>
                      </li>
                      <li className="border-t border-gray-200">
                        <a
                          href="#leave"
                          className="block px-6 py-2 text-sm text-red-600 hover:bg-gray-50 dark:text-red-500 dark:hover:bg-navy-600"
                          onClick={undeployTenant}
                        >
                          Delete
                        </a>
                      </li>
                    </ul>
                  </div>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TenantSummary;
