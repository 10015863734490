import React, { useState, useEffect } from 'react';
import { FaUsersCog } from 'react-icons/fa';
import { formatDateTime } from 'utilities/formatDateTime';
import { useParams } from 'react-router-dom';
import { getTeams, deleteTeam } from 'services/api/teamsService';
import CreateTeam from './CreateTeam';
import GetTeam from './GetTeam';
import TeamMembers from './TeamMembers';
import Loading from 'views/loading/Loading';

const Teams = ({ slug }) => {
  const { slug: currentSlug } = useParams();
  const slugToUse = slug || currentSlug;
  const [teams, setTeams] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [showCreateTeam, setShowCreateTeam] = useState(false);
  const [teamToDelete, setTeamToDelete] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [confirmText, setConfirmText] = useState('');
  const [selectedTeam, setSelectedTeam] = useState(null);

  useEffect(() => {
    if (!showCreateTeam && !selectedTeam) {
      const fetchTeams = async () => {
        try {
          const data = await getTeams(slugToUse);
          if (data) {
            setTeams(data);
          } else {
            setTeams([]);
          }
        } catch (error) {
          setError('Failed to load teams.');
          console.error('Error fetching teams:', error);
        } finally {
          setLoading(false);
        }
      };

      fetchTeams();
    }
  }, [showCreateTeam, slugToUse, selectedTeam]);

  const handleCreateTeamClick = () => {
    setShowCreateTeam(true);
  };

  const handleBackToTeams = () => {
    setShowCreateTeam(false);
    setSelectedTeam(null);
  };

  const handleDeleteTeam = async () => {
    if (teamToDelete && confirmText === 'confirm') {
      try {
        if (teamToDelete.identity) {
          await deleteTeam(teamToDelete.identity, slug);
          setTeams(prevTeams =>
            prevTeams.filter(team => team.identity !== teamToDelete.identity),
          );
          setShowConfirmModal(false);
          setTeamToDelete(null);
          setConfirmText('');
        } else {
          console.error('Team ID is missing.');
        }
      } catch (error) {
        setError(`Failed to delete team: ${error.message}`);
        console.error('Error deleting team:', error);
      }
    } else {
      console.error('Confirmation text does not match or team ID is missing.');
    }
  };

  const handleCancelConfirm = () => {
    setShowConfirmModal(false);
    setTeamToDelete(null);
    setConfirmText('');
  };

  const handleTeamClick = team => {
    setSelectedTeam(team);
  };

  if (selectedTeam) {
    return (
      <div>
        <GetTeam teamId={selectedTeam.identity} organisationId={slug} />
        <div className="my-4">
          <TeamMembers teamId={selectedTeam.identity} organisationId={slug} />
        </div>
      </div>
    );
  }

  return (
    <div className="">
      {showCreateTeam ? (
        <CreateTeam
          slug={slug}
          onTeamCreated={newTeam => {
            setTeams(prevTeams => [...prevTeams, newTeam]);
            setShowCreateTeam(false);
          }}
          onCancel={handleBackToTeams}
        />
      ) : (
        <>
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-sm font-bold lg:text-lg dark:text-white">
              Teams
            </h2>
            <button
              onClick={handleCreateTeamClick}
              className="flex flex-row items-center bg-customBlue hover:bg-gray-900 hover:shadow-lg text-white text-xs font-semibold py-2 px-4 rounded-md lg:text-sm dark:bg-navy-600 dark:hover:bg-navy-700"
            >
              <FaUsersCog className="mr-2" />
              New Team
            </button>
          </div>
          {loading ? (
            <Loading />
          ) : error ? (
            <p className="text-red-500">{error}</p>
          ) : teams.length > 0 ? (
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-400 dark:bg-navy-700">
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                      Name
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                      Members
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                      Created At
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200  dark:bg-navy-700">
                  {teams.map(team => (
                    <tr
                      className="hover:bg-gray-50 transition dark:hover:bg-navy-600"
                      key={team.identity}
                    >
                      <td
                        className="px-6 py-4 whitespace-nowrap text-sm text-blue-600 hover:text-blue-700 cursor-pointer dark:text-white"
                        onClick={() => handleTeamClick(team)}
                      >
                        {team.name}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-white">
                        {team.members ? team.members.length : 0}{' '}
                        {team.members && team.members.length === 1
                          ? 'Member'
                          : 'Members'}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-white">
                        {formatDateTime(team.createdAt)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-white">
                        <button
                          onClick={() => {
                            setTeamToDelete(team);
                            setShowConfirmModal(true);
                          }}
                          className="text-red-500 hover:text-red-700 dark:text-red-400"
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <p className="text-sm lg:text-md dark:text-white">
              No teams available.
            </p>
          )}
        </>
      )}
      {showConfirmModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="bg-white p-8 rounded-lg shadow-lg w-[33rem] h-[20rem]">
            <div className="flex justify-between items-center border-b pb-2 mb-4">
              <h3 className="text-lg font-bold">Confirm Deletion</h3>
              <button
                onClick={handleCancelConfirm}
                className="text-gray-500 hover:text-gray-800"
              >
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              </button>
            </div>
            <p>Do you want to delete the team: {teamToDelete?.name}?</p>
            <input
              type="text"
              placeholder="Type 'confirm' to confirm"
              className="border border-gray-300 p-2 mt-10 w-full"
              value={confirmText}
              onChange={e => setConfirmText(e.target.value)}
            />
            <div className="flex justify-end mt-12">
              <button
                onClick={handleCancelConfirm}
                className="bg-gray-500 text-white px-4 py-2 rounded-md mr-2"
              >
                Cancel
              </button>
              <button
                onClick={handleDeleteTeam}
                className="bg-red-500 text-white px-4 py-2 rounded-md"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Teams;
