import React, { useState, useEffect } from 'react';
import OrganizationWidget from 'utilities/OrganizationWidget';
import { getUserInfo } from 'services/api/memberService';
import { formatDateTime } from 'utilities/formatDateTime';
import KeycloakService from 'services/KeycloakService';
import { FaEdit } from 'react-icons/fa';
import { IoClose } from 'react-icons/io5';
import avatar from 'assets/img/avatars/avatar4.png';

export default function Settings({ organizationName, role, orgSlug }) {
  const [userData, setUserData] = useState([]);
  const [organizationId, setOrganizationId] = useState(null);
  const [profilePicture, setProfilePicture] = useState(avatar);
  const [showModal, setShowModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const imageInputRef = React.useRef(null);

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const data = await getUserInfo(organizationId);
        setUserData(data);
      } catch (err) {
        console.error('Failed to fetch user info.');
      }
    };

    if (organizationId) {
      fetchUserInfo();
    }
  }, [organizationId]);

  useEffect(() => {
    if (KeycloakService.isLoggedIn()) {
      KeycloakService.getUserProfile().then(profile => {
        setProfilePicture(
          profile.googleProfilePhotoUrl || profile.githubAvatarUrl || avatar,
        );
      });
    }
  }, []);

  const handleImageUpload = e => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      const imageUrl = URL.createObjectURL(file);
      setProfilePicture(imageUrl);
    }
  };

  const handleImageClick = () => {
    console.log('handleImageClick');
    setShowModal(true);
    console.log(showModal);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleRemoveAvatar = () => {
    setProfilePicture(avatar);
    setSelectedFile(null);
    setShowModal(false);
  };

  const handleUpdateAvatar = () => {
    if (selectedFile) {
      setShowModal(false);
    }
  };

  return (
    <div className="max-w-8xl mx-auto mt-14 px-6">
      {/* Organization Widget */}
      <OrganizationWidget
        organizationName={organizationName}
        organizationSlug={orgSlug}
        role={role}
        setOrganizationId={setOrganizationId}
      />

      <div className="mt-14">
        <div className="flex items-center gap-3 mb-3">
          <div className="relative group">
            <img
              className="w-16 h-16 rounded object-cover transition duration-300 ease-in-out group-hover:grayscale cursor-pointer"
              src={profilePicture}
              alt="Profile"
              onClick={handleImageClick}
            />
            <label
              htmlFor="image-input"
              className="absolute inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 rounded-full opacity-0 group-hover:opacity-100 transition duration-300 ease-in-out pointer-events-none"
            >
              <FaEdit className="text-white text-xl" />
            </label>
          </div>

          <div className="flex flex-col mt-2">
            <h3 className="text-md font-semibold text-gray-700 dark:text-white">
              Welcome{' '}
              <span className="text-customBlue dark:text-blue-500">
                {userData?.[0]?.user?.displayName}
              </span>
            </h3>
            <p className="text-xs text-gray-700 dark:text-gray-400 mb-4">
              Created At:{' '}
              {formatDateTime(userData?.[0]?.user?.createdAt) || 'N/A'}
            </p>
          </div>
        </div>

        {/* Modal for Changing Avatar */}
        {showModal && (
          <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50  dark:bg-opacity-50">
            <div className="bg-white dark:bg-navy-700 rounded-lg shadow-lg p-6 w-96">
              {/* Modal Header */}
              <div className="flex items-center justify-between border-b w-full pb-3 mb-5">
                <h3 className="text-md font-semibold text-gray-700 dark:text-white">
                  Change Avatar
                </h3>
                <IoClose
                  className="text-xl text-gray-600 dark:text-gray-300 cursor-pointer"
                  onClick={handleCloseModal}
                />
              </div>

              {/* Image Preview Box */}
              <div className="border border-gray-300 dark:border-gray-600 rounded-md p-4 mb-4">
                <img
                  src={profilePicture}
                  alt="Avatar Preview"
                  className="w-32 h-32 mx-auto rounded object-cover"
                />
              </div>

              {/* Select Image Button */}
              <div className="flex justify-center mb-6">
                <button
                  onClick={() => imageInputRef.current.click()}
                  className="bg-customBlue text-white text-xs px-2 py-1 rounded item-center  hover:bg-gray-800 transition"
                >
                  Select Image
                </button>
                <input
                  id="image-input"
                  ref={imageInputRef}
                  type="file"
                  accept="image/*"
                  onChange={handleImageUpload}
                  className="hidden"
                />
              </div>

              {/* Remove & Update Avatar Buttons */}
              <div className="flex justify-end gap-3">
                <button
                  onClick={handleRemoveAvatar}
                  className="bg-red-500 text-xs text-white px-2 py-1 rounded hover:bg-red-600 transition dark:bg-red-400 dark:hover:bg-red-500"
                >
                  Remove Avatar
                </button>
                <button
                  onClick={handleUpdateAvatar}
                  className="bg-blue-500 text-xs text-white px-3 py-1 rounded hover:bg-blue-600 transition"
                >
                  Update Avatar
                </button>
              </div>
            </div>
          </div>
        )}

        <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
          {/* Profile Section */}
          <div className="bg-white dark:bg-navy-700 shadow-md rounded-lg p-6">
            <div className="flex items-center justify-between border-b pb-3 mb-4 dark:border-gray-700">
              <h3 className="text-md font-semibold text-gray-700 dark:text-white">
                Profile
              </h3>
              <button className="text-sm text-blue-500 hover:underline">
                Edit
              </button>
            </div>
            <div className="space-y-4">
              <div className="flex items-center">
                <p className="text-sm text-gray-700 dark:text-gray-400 w-24">
                  Name:
                </p>
                <p className="text-sm text-gray-900 dark:text-white">
                  {userData?.[0]?.user?.displayName || 'N/A'}
                </p>
              </div>
              <div className="flex items-center">
                <p className="text-sm text-gray-700 dark:text-gray-400 w-24">
                  Email:
                </p>
                <p className="text-sm text-gray-900 dark:text-white">
                  {userData?.[0]?.user?.email || 'N/A'}
                </p>
              </div>
            </div>
          </div>

          {/* Security Section */}
          <div className="bg-white dark:bg-navy-700 shadow-md rounded-lg p-6">
            <div className="flex items-center justify-between border-b pb-3 mb-4 dark:border-gray-700">
              <h3 className="text-md font-semibold text-gray-700 dark:text-white">
                Security
              </h3>
              <button className="text-sm text-blue-500 hover:underline">
                Edit
              </button>
            </div>
            <div className="space-y-4">
              <div className="flex items-center">
                <p className="text-sm text-gray-700 dark:text-gray-400 w-24">
                  Password:
                </p>
                <p className="text-gray-900 dark:text-white">********</p>
              </div>
              <div className="flex items-center">
                <p className="text-sm text-gray-700 dark:text-gray-400 w-24">
                  2FA:
                </p>
                <p className="text-gray-900 dark:text-white">Enabled</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
