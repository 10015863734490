import React, { useState, useEffect } from 'react';
import CreateTenant from './components/tenant/CreateTenant';
import TenantsView from './components/tenant/TenantView';
import { getOrganisationMemberships } from 'services/api/organizationService';
import { getTenants } from 'services/api/tenantService';
import { FaBuilding, FaUserTie, FaTag } from 'react-icons/fa';
import Widget from 'components/widget/Widget';

const Tenants = () => {
  const [tenants, setTenants] = useState([]);
  const [showCreateTenant, setShowCreateTenant] = useState(false);
  const [memberships, setMemberships] = useState([]);
  const [selectedOrganisation, setSelectedOrganisation] = useState(null);
  const [organisations, setOrganisations] = useState([]);
  const [error, setError] = useState(null);
  const [fetchedData, setFetchedData] = useState([]);

  useEffect(() => {
    const fetchOrganisationData = async () => {
      try {
        const response = await getOrganisationMemberships();
        setFetchedData(response);

        if (response && response.length > 0) {
          const firstOrg = response[0];
          setOrganisations(response.map(org => org.organisation));
          setSelectedOrganisation(firstOrg.organisation);
          setMemberships({ role: firstOrg.role });
        } else {
          console.error('Error: No organization data found.');
        }
      } catch (error) {
        console.error('Error fetching organization data:', error);
        setError('Failed to load organization data');
      }
    };

    fetchOrganisationData();
  }, []);

  useEffect(() => {
    const fetchTenants = async () => {
      if (!selectedOrganisation) return;

      try {
        setTenants([]);

        const tenantsData = await getTenants(selectedOrganisation.identity);
        setTenants(tenantsData);
      } catch (err) {
        console.error('Error fetching tenants data:', err);
        setError('Failed to load tenants');
      }
    };

    fetchTenants();
  }, [selectedOrganisation]);
  const handleCreateTenant = newTenant => {
    setTenants(prevTenants => [...prevTenants, newTenant]);
    setShowCreateTenant(false);
  };

  const handleOrganisationChange = e => {
    const orgId = e.target.value;
    const org = organisations.find(o => o.identity === orgId);
    const membership = fetchedData.find(m => m.organisation.identity === orgId);
    setMemberships({ role: membership.role });
    setSelectedOrganisation(org);
  };

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  return (
    <div className="max-w-8xl mx-auto mt-12">
      {/* Organization Widget */}
      <div className="mt-10 grid grid-cols-1 gap-5 mb-6 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6">
        {selectedOrganisation && (
          <>
            <Widget
              icon={<FaBuilding className="h-7 w-7" />}
              title={'Organization'}
              subtitle={selectedOrganisation.name || 'N/A'}
            />
            <Widget
              icon={<FaUserTie className="h-6 w-6" />}
              title={'Role'}
              subtitle={memberships?.role || 'N/A'}
            />
            <Widget
              icon={<FaTag className="h-7 w-7" />}
              title={'OrgID'}
              subtitle={selectedOrganisation.slug || 'N/A'}
            />
          </>
        )}
      </div>

      {/* Organization Dropdown */}
      <div className="my-8">
        <label
          htmlFor="organisationDropdown"
          className="block text-sm font-medium text-gray-700"
        >
          Select an Organization
        </label>
        <select
          id="organisationDropdown"
          className="mt-2 block w-full p-2 text-sm border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 lg:w-[690px] dark:bg-navy-700 dark:border-gray-700 dark:text-white dark:focus:ring-navy-600 dark:focus:border-navy-600"
          onChange={handleOrganisationChange}
          value={selectedOrganisation?.identity || ''}
        >
          {organisations.map(org => (
            <option key={org.identity} value={org.identity}>
              {org.name}
            </option>
          ))}
        </select>
      </div>

      {/* Tenants List or Create Tenant Modal */}
      <div className="mt-5">
        {tenants && tenants.length > 0 ? (
          <TenantsView
            organizationId={selectedOrganisation.identity}
            tenants={tenants}
          />
        ) : showCreateTenant ? (
          <CreateTenant
            organizationId={selectedOrganisation.identity}
            onCreate={handleCreateTenant}
            onCancel={() => setShowCreateTenant(false)}
          />
        ) : (
          <div className=" dark:text-white">
            <p>
              No tenant data found. You can{' '}
              <button
                onClick={() => setShowCreateTenant(true)}
                className="text-indigo-600 hover:underline focus:outline-none dark:text-blue-400"
              >
                create a new tenant
              </button>{' '}
              to proceed.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Tenants;
