import React, { useState, useEffect } from 'react';
import OrganizationCard from './components/OrganizationCard';
import TenantCard from './components/TenantCard';
import MemberCard from './components/MemberCard';
import TeamCard from './components/TeamCard';
import { getOrganisationMember } from 'services/api/organizationService';
import { getOrganisationMemberships } from 'services/api/organizationService';

const MainDashboard = () => {
  const [member, setMember] = useState([]);

  useEffect(() => {
    const getOrganization = async () => {
      try {
        const getOrg = await getOrganisationMemberships();
        const response = await getOrganisationMember(
          getOrg[0].organisation.identity,
        );
        setMember(response);
      } catch (error) {
        console.error('Failed to fetch organization memberships', error);
      }
    };

    getOrganization();
  }, []);

  return (
    <div className="max-w-7xl mx-auto mt-14">
      {/* Dashboard Grid */}
      <section className="w-full max-w-full mt-6">
        <h2 className="text-sm font-bold text-gray-800 border-b pb-4 mb-6 dark:text-white">
          {member && member.length > 0
            ? `Welcome ${member[0].user.displayName}`
            : 'Welcome'}
        </h2>

        {/* Grid Layout for Widgets */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
          {/* Left Column */}
          <div className="grid grid-cols-1 gap-4">
            {/* Organizations Widget */}
            <div className=" h-full">
              <OrganizationCard />
            </div>
            {/* Tenants Widget */}
            <div className=" h-full">
              <TenantCard />
            </div>
            {/* Members Widget */}
            <div className=" h-full">
              <MemberCard />
            </div>
          </div>

          {/* Right Column */}
          <div className="grid grid-cols-1 gap-4">
            {/* Namespace Widget */}
            <div className=" h-full ">
              <TeamCard />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default MainDashboard;
