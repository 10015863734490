import React, { useState } from 'react';
import { getKubeconfig } from 'services/api/tenantService';
import { toast } from 'react-toastify';

const KubeconfigFetcher = ({ tenantId, organizationId }) => {
  const [kubeconfig, setKubeconfig] = useState('');
  const [loading, setLoading] = useState(false);

  const fetchKubeconfig = async () => {
    setLoading(true);
    try {
      const response = await getKubeconfig(tenantId, organizationId);
      setKubeconfig(response.data.kubeConfig);
      toast.success('Kubeconfig fetched successfully', {
        autoClose: 3000,
        position: 'top-center',
      });
    } catch (err) {
      console.error(`Failed to fetch kubeconfig: ${err.message}`);
      toast.error('Failed to fetch kubeconfig', {
        autoClose: 3000,
        position: 'top-center',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-7xl mx-auto">
      <h4 className="text-sm font-bold  text-gray-800 pb-2 mb-6 lg:text-lg dark:text-white">
        Tenant Admin Access
      </h4>

      <button
        className={`flex flex-row items-center bg-customBlue hover:bg-gray-900 hover:shadow-lg text-white text-xs font-semibold py-2 px-4 rounded-md lg:text-sm ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
        onClick={fetchKubeconfig}
        disabled={loading}
      >
        {loading ? 'Fetching...' : 'Get Kubeconfig File'}
      </button>

      {kubeconfig && (
        <div className="mt-8">
          <pre className="bg-gray-100 p-6 rounded-lg shadow-inner overflow-auto dark:bg-navy-700 dark:text-white">
            {kubeconfig}
          </pre>
        </div>
      )}
    </div>
  );
};

export default KubeconfigFetcher;
