import { useEffect, useState } from 'react';
import WidgetCard from 'components/widget/WidgetCard';
import { getOrganisationMemberships } from 'services/api/organizationService';
import { getTeams } from 'services/api/teamsService';
import MemberRoleBadge from 'utilities/MemberRoleBadge';

const TeamCard = () => {
  const [memberships, setMemberships] = useState([]);
  const [selectedOrgId, setSelectedOrgId] = useState(null);
  const [teamMembers, setTeamMembers] = useState([]);
  const [teamName, setTeamName] = useState('');

  useEffect(() => {
    const getOrganization = async () => {
      try {
        const response = await getOrganisationMemberships();
        setMemberships(response);
        if (response.length > 0) {
          setSelectedOrgId(response[0].organisation.identity);
        }
      } catch (error) {
        console.error('Failed to fetch organization memberships', error);
      }
    };

    getOrganization();
  }, []);

  useEffect(() => {
    if (selectedOrgId) {
      const getTeamInfo = async () => {
        try {
          const response = await getTeams(selectedOrgId);
          setTeamMembers(response[0]?.members || []);
          setTeamName(response[0]?.name);
        } catch (error) {
          console.error('Failed to fetch team info', error);
        }
      };

      getTeamInfo();
    }
  }, [selectedOrgId]);

  const handleOrganizationChange = event => {
    const orgId = event.target.value;
    setSelectedOrgId(orgId);
    setTeamMembers([]);
  };

  return (
    <WidgetCard
      title={
        <h1 className="">
          {teamName ? `${teamName}'s Team` : 'No Team Available'}
        </h1>
      }
    >
      {/* Organization Selection */}
      <div className="mb-4">
        <label
          htmlFor="organization-select"
          className="block text-xs font-medium text-gray-700 dark:text-gray-300"
        >
          Select Organization
        </label>
        <select
          id="organization-select"
          value={selectedOrgId || ''}
          onChange={handleOrganizationChange}
          className="mt-1 block w-full p-1 text-xs border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 dark:bg-navy-700 dark:border-navy-600 dark:text-white"
        >
          {memberships.map(membership => (
            <option
              key={membership.organisation.identity}
              value={membership.organisation.identity}
            >
              {membership.organisation.name}
            </option>
          ))}
        </select>
      </div>

      {/* Team Members List */}
      <div className="overflow-x-auto">
        <table className="min-w-full border border-gray-200 divide-y divide-gray-300 dark:border-navy-600 dark:divide-navy-600">
          <thead className="bg-gray-100 dark:bg-navy-800">
            <tr>
              <th className="px-2 py-2 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                Team Member
              </th>
              <th className="px-2 py-2 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                Role
              </th>
            </tr>
          </thead>
          <tbody className="bg-white dark:bg-navy-700 divide-y divide-gray-300 dark:divide-navy-600">
            {teamMembers.length > 0 ? (
              teamMembers.map((member, index) => (
                <tr
                  key={index}
                  className="hover:bg-gray-50 dark:hover:bg-navy-600"
                >
                  <td className="px-2 py-2 whitespace-nowrap text-xs text-gray-900 dark:text-white">
                    {member.user.displayName || `Member ${index + 1}`}
                  </td>
                  <td className="px-2 py-2 whitespace-nowrap text-xs text-gray-500 dark:text-gray-400">
                    <MemberRoleBadge role={member.role} size="sm" />
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan="2"
                  className="px-4 py-2 text-xs text-center text-gray-500 dark:text-gray-400"
                >
                  No team members available.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </WidgetCard>
  );
};

export default TeamCard;
