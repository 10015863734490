import { useEffect, useState } from 'react';
import WidgetCard from 'components/widget/WidgetCard';
import { getOrganisationMemberships } from 'services/api/organizationService';
import RoleBadge from 'utilities/RoleBadge';

const OrganizationCard = () => {
  const [memberships, setMemberships] = useState([]);

  useEffect(() => {
    const getOrganization = async () => {
      try {
        const response = await getOrganisationMemberships();
        setMemberships(response);
      } catch (error) {
        console.error('Failed to fetch organization memberships', error);
      }
    };

    getOrganization();
  }, []);

  return (
    <WidgetCard
      title={
        <h1 className="">
          {memberships.length === 1
            ? 'You are a member of the following organization'
            : `You are a member of ${memberships.length} organizations`}
        </h1>
      }
    >
      {/* Organizations List */}
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-300 border border-gray-200 dark:border-navy-600 dark:divide-navy-600">
          <thead className="bg-gray-100 dark:bg-navy-800">
            <tr>
              <th className="px-2 py-2 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                Organization Name
              </th>
              <th className="px-2 py-2 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                Role
              </th>
            </tr>
          </thead>
          <tbody className="bg-white dark:bg-navy-700 divide-y divide-gray-300 dark:divide-navy-600">
            {memberships.length > 0 ? (
              memberships.map((org, index) => (
                <tr
                  key={index}
                  className=" hover:bg-gray-50 dark:hover:bg-navy-600"
                >
                  <td className="px-2 py-1 whitespace-nowrap text-xs text-gray-900 dark:text-white">
                    {org.organisation.name || `Organization ${index + 1}`}
                  </td>
                  <td className="px-2 py-1  whitespace-nowrap text-xs text-gray-500 dark:text-gray-400">
                    <RoleBadge role={org.role} size="sm" />
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan="2"
                  className="px-4 py-2 text-xs text-center text-gray-500 dark:text-gray-400"
                >
                  No organizations available.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </WidgetCard>
  );
};

export default OrganizationCard;
