import apiClient from "../apiService";



export const getAllHostClusters = async () => {
    try {
        const response = await apiClient.get('/internal/v1/hostclusters');
        return response.data;
    } catch (err) {
        console.error('Error getting host clusters:', err);
        return null;
    }
}

export const createHostCluster = async (hostCluster) => {
    try {
        const response = await apiClient.post('/internal/v1/hostclusters', hostCluster);
        return response;
    } catch (err) {
        console.error('Error creating host cluster:', err.response ? err.response.data : err.message);
        throw err;
    }
}

export const deleteHostCluster = async (identity) => {
    try {
        await apiClient.delete(`/internal/v1/hostclusters/${identity}`);
    } catch (err) {
        console.error('Error deleting host cluster:', err);
        throw err;
    }
}

export const getHostClusterCloud = async (identity) => {
    try {
        const response = await apiClient.get(`/internal/v1/hostclusters/${identity}/clouds`);
        return response.data;
    } catch (err) {
        console.error('Error getting host cluster:', err);
        return null;
    }
}

export const createUserHostCluster = async (organisationId) => {
    try {
      const response = await apiClient.post(
        '/v1/appusers/host-cluster',
        {
          cloud: "azure",     
          region: "europe-west"
        },
        {
          headers: {
            'X-Organisation-Identity': organisationId, 
          },
        }
      );
      return response;
    } catch (err) {
      console.error(
        'Error creating user host cluster:',
        err.response ? err.response.data : err.message
      );
      throw err;
    }
  };
  