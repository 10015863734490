import React, { useState, useEffect } from 'react';
import CreateNamespaceModal from './components/namespaces/CreateNamespace';
import NamespacesList from './components/namespaces/NamespacesList';
import Resources from './components/namespaces/Resources';
import { getTenants } from 'services/api/tenantService';
import { getOrganisationMemberships } from 'services/api/organizationService';
import Loading from 'views/loading/Loading';
import { FaBuilding, FaUserTie, FaTag } from 'react-icons/fa';
import Widget from 'components/widget/Widget';

const Namespaces = () => {
  const [organizationId, setOrganizationId] = useState(null);
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [organizations, setOrganizations] = useState([]);
  const [selectedTenant, setSelectedTenant] = useState(null);
  const [namespaces, setNamespaces] = useState({});
  const [tenants, setTenants] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedNamespace, setSelectedNamespace] = useState(null);
  const [memberships, setMemberships] = useState([]);
  const [fetchedData, setFetchedData] = useState([]);

  useEffect(() => {
    const fetchOrganisationData = async () => {
      try {
        const response = await getOrganisationMemberships();
        setFetchedData(response);

        if (response && response.length > 0) {
          const firstOrg = response[0];
          setOrganizations(response.map(org => org.organisation));
          setSelectedOrganization(firstOrg.organisation);
          setOrganizationId(firstOrg.organisation.identity);
          setMemberships({ role: firstOrg.role });
        } else {
          console.error('Error: No organization data found.');
        }
      } catch (error) {
        console.error('Error fetching organization data:', error);
      }
    };

    fetchOrganisationData();
  }, []);

  useEffect(() => {
    const fetchTenants = async () => {
      if (!selectedOrganization) return;

      setLoading(true);
      try {
        const tenantData = await getTenants(selectedOrganization.identity);
        setTenants(tenantData);

        const namespacesData = {};
        tenantData.forEach(tenant => {
          namespacesData[tenant.identity] = tenant.namespaces;
        });
        setNamespaces(namespacesData);
      } catch (error) {
        console.error('Failed to fetch tenants:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchTenants();
  }, [selectedOrganization]);

  const handleCreateNamespace = async newNamespace => {
    if (!selectedTenant || !organizationId) return;

    const namespaceData = {
      ...newNamespace,
      identity: newNamespace.identity,
      createdAt: new Date().toISOString(),
    };
    setNamespaces(prevNamespaces => ({
      ...prevNamespaces,
      [selectedTenant.identity]: [
        ...(prevNamespaces[selectedTenant.identity] || []),
        namespaceData,
      ],
    }));
  };

  const handleOrganizationSelection = e => {
    const orgId = e.target.value;
    const org = organizations.find(o => o.identity === orgId);
    const membership = fetchedData.find(m => m.organisation.identity === orgId);
    setMemberships({ role: membership.role });
    setSelectedOrganization(org);
    setSelectedTenant(null);
  };

  const handleTenantSelection = e => {
    const tenantId = e.target.value;
    const tenant = tenants.find(tenant => tenant.identity === tenantId);
    setSelectedTenant(tenant);
    setSelectedNamespace(null);
  };

  const handleNamespaceUpdate = updatedNamespaceData => {
    setNamespaces(prevNamespaces => ({
      ...prevNamespaces,
      [selectedTenant.identity]: prevNamespaces[selectedTenant.identity].map(
        namespace =>
          namespace.identity === updatedNamespaceData.identity
            ? updatedNamespaceData
            : namespace,
      ),
    }));
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="max-w-8xl mx-auto mt-12">
      {/* Organization Widget */}
      <div className="mt-10 grid grid-cols-1 gap-5 mb-6 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6">
        {selectedOrganization && (
          <>
            <Widget
              icon={<FaBuilding className="h-7 w-7" />}
              title={'Organization'}
              subtitle={selectedOrganization.name || 'N/A'}
            />
            <Widget
              icon={<FaUserTie className="h-6 w-6" />}
              title={'Role'}
              subtitle={memberships?.role || 'N/A'}
            />
            <Widget
              icon={<FaTag className="h-7 w-7" />}
              title={'OrgID'}
              subtitle={selectedOrganization.slug || 'N/A'}
            />
          </>
        )}
      </div>
      {/* Organization Selection */}
      <div className="flex flex-col gap-3 mt-8 lg:flex-row">
        <div className="mb-6">
          <label
            htmlFor="organization-select"
            className="block text-sm font-medium text-gray-700 mb-2"
          >
            Select an Organization
          </label>
          <select
            id="organization-select"
            onChange={handleOrganizationSelection}
            value={selectedOrganization ? selectedOrganization.identity : ''}
            className="mt-2 block w-full p-2 text-sm border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 lg:w-[340px] dark:bg-navy-700 dark:border-gray-700 dark:text-white dark:focus:ring-navy-600 dark:focus:border-navy-600"
          >
            <option value="">-- Select an Organization --</option>
            {organizations.map(org => (
              <option key={org.identity} value={org.identity}>
                {org.name}
              </option>
            ))}
          </select>
        </div>

        {/* Tenant Selection */}
        {selectedOrganization && tenants.length > 0 && (
          <div className="mb-6">
            <label
              htmlFor="tenant-select"
              className="block text-sm font-medium text-gray-700 mb-2"
            >
              Select a Tenant
            </label>
            <select
              id="tenant-select"
              onChange={handleTenantSelection}
              value={selectedTenant ? selectedTenant.identity : ''}
              className="mt-2 block w-full p-2 text-sm border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 lg:w-[340px] dark:bg-navy-700 dark:border-gray-700 dark:text-white dark:focus:ring-navy-600 dark:focus:border-navy-600"
            >
              <option value="">-- Select a Tenant --</option>
              {tenants.map(tenant => (
                <option key={tenant.identity} value={tenant.identity}>
                  {tenant.name}
                </option>
              ))}
            </select>
          </div>
        )}
      </div>
      {/* Display Namespaces */}
      {selectedTenant ? (
        namespaces[selectedTenant.identity]?.length > 0 ? (
          <>
            <NamespacesList
              namespaces={namespaces[selectedTenant.identity]}
              onAddNamespace={() => setIsModalOpen(true)}
              onSelectNamespace={namespace => setSelectedNamespace(namespace)}
              organizationId={organizationId}
              tenantId={selectedTenant.identity}
              onDeleteNamespace={namespaceIdentity => {
                const updatedNamespaces = namespaces[
                  selectedTenant.identity
                ].filter(namespace => namespace.identity !== namespaceIdentity);
                setNamespaces(prevNamespaces => ({
                  ...prevNamespaces,
                  [selectedTenant.identity]: updatedNamespaces,
                }));
              }}
              onNamespaceUpdated={handleNamespaceUpdate}
            />

            {/* Render Resources component when a namespace is selected */}
            {selectedNamespace && (
              <Resources
                organizationId={organizationId}
                tenantId={selectedTenant.identity}
                namespaceId={selectedNamespace.identity}
              />
            )}
          </>
        ) : (
          <div className="mt-8 dark:text-white">
            <p>
              {' '}
              No namespaces available for this tenant. You can{' '}
              <button
                onClick={() => setIsModalOpen(true)}
                className="text-indigo-600 hover:underline focus:outline-none dark:text-blue-400"
              >
                create a new namespace
              </button>{' '}
              to proceed.
            </p>
          </div>
        )
      ) : (
        <div className="mt-5 dark:text-white">No tenant selected.</div>
      )}

      {/* Modal for creating a namespace */}
      <CreateNamespaceModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        organizationId={organizationId}
        tenantId={selectedTenant ? selectedTenant.identity : ''}
        onCreate={handleCreateNamespace}
      />
    </div>
  );
};

export default Namespaces;
