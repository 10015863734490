import React, { useState, useEffect } from 'react';
import { FaEnvelope } from 'react-icons/fa';
import { getTeam, deleteMemberFromTeam } from 'services/api/teamsService';
import { formatDateTime } from 'utilities/formatDateTime';
import AddMember from './AddMember';
import Loading from 'views/loading/Loading';
import MemberRoleBadge from 'utilities/MemberRoleBadge';
import { toast } from 'react-toastify';

const TeamMembers = ({ teamId, organisationId }) => {
  const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [showAddMember, setShowAddMember] = useState(false);

  useEffect(() => {
    const fetchMembers = async () => {
      try {
        const data = await getTeam(teamId, organisationId);
        setMembers(data.data.members);
      } catch (error) {
        setError('Failed to load members.');
        console.error('Error fetching members:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchMembers();
  }, [teamId, organisationId]);

  const handleMemberAdded = async () => {
    const data = await getTeam(teamId, organisationId);
    setMembers(data.data.members);
  };

  const handleDeleteMember = async member => {
    try {
      await deleteMemberFromTeam(teamId, member.identity, organisationId);
      setMembers(prevMembers =>
        prevMembers.filter(m => m.identity !== member.identity),
      );
      toast.success('Member deleted successfully.', {
        autoClose: 3000,
        position: 'top-center',
      });
    } catch (error) {
      setError('Failed to delete member.');
      toast.error('Failed to delete member.', {
        autoClose: 3000,
        position: 'top-center',
      });
      console.error('Error deleting member:', error);
    }
  };

  if (showAddMember) {
    return (
      <AddMember
        teamId={teamId}
        organisationId={organisationId}
        role="member"
        onClose={() => setShowAddMember(false)}
        onMemberAdded={handleMemberAdded}
      />
    );
  }

  return (
    <div className="">
      <div className="flex flex-row justify-between items-center pb-2 mb-4">
        <h2 className="text-sm font-bold lg:text-lg dark:text-white">
          Team Members
        </h2>
        <button
          onClick={() => setShowAddMember(true)}
          className="flex flex-row items-center bg-customBlue hover:bg-gray-900 hover:shadow-lg text-white text-xs font-semibold py-2 px-4 rounded-md lg:text-sm"
        >
          <FaEnvelope className="mr-2" />
          Add Member
        </button>
      </div>
      {loading ? (
        <Loading />
      ) : error ? (
        <p className="text-red-500">{error}</p>
      ) : members.length > 0 ? (
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-400 dark:bg-navy-700">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                  Name
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                  Email
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                  Added At
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                  Role
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200  dark:bg-navy-700 ">
              {members.map(member => (
                <tr
                  className="hover:bg-gray-50 transition dark:hover:bg-navy-600"
                  key={member.identity}
                >
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-white">
                    {member.user.displayName}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-white">
                    {member.user.email}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-white">
                    {formatDateTime(member.createdAt)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm">
                    <MemberRoleBadge role={member.role} />
                  </td>
                  <td
                    className="px-6 py-4 whitespace-nowrap text-sm text-red-500 hover:text-red-700 cursor-pointer dark:text-red-400"
                    onClick={() => handleDeleteMember(member)}
                  >
                    Delete
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p>No members available.</p>
      )}
    </div>
  );
};

export default TeamMembers;
