import React, { useState, useEffect, useRef } from 'react';
import { formatDateTime } from 'utilities/formatDateTime';
import { deleteNamespaces } from 'services/api/namespaceService';
import { FaPlus, FaEllipsisV } from 'react-icons/fa';
import { toast } from 'react-toastify';
import UpdateNamespaceModal from './UpdateNamespace';

const NamespacesList = ({
  namespaces,
  onSelectNamespace,
  onAddNamespace,
  organizationId,
  tenantId,
  onDeleteNamespace,
  onNamespaceUpdated,
}) => {
  const [openMenu, setOpenMenu] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [namespaceToRemove, setNamespaceToRemove] = useState(null);
  const [confirmText, setConfirmText] = useState('');
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [namespaceToEdit, setNamespaceToEdit] = useState(null);

  const menuRef = useRef(null);

  const toggleMenu = namespaceId => {
    setOpenMenu(openMenu === namespaceId ? null : namespaceId);
  };

  useEffect(() => {
    const handleClickOutside = event => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setOpenMenu(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleDelete = async namespaceIdentity => {
    try {
      await deleteNamespaces(organizationId, tenantId, namespaceIdentity);
      onDeleteNamespace(namespaceIdentity);
      toast.success('Namespace deleted successfully.', {
        autoClose: 3000,
        position: 'top-center',
      });
    } catch (err) {
      toast.error('Error deleting namespace.', {
        autoClose: 3000,
        position: 'top-center',
      });
      console.error('Error deleting namespace:', err);
    }
  };

  const handleConfirmRemove = async () => {
    if (namespaceToRemove && confirmText === 'confirm') {
      handleDelete(namespaceToRemove.identity);
      setShowConfirmModal(false);
      setNamespaceToRemove(null);
      setConfirmText('');
    }
  };

  const handleCancelRemove = () => {
    setShowConfirmModal(false);
    setNamespaceToRemove(null);
    setConfirmText('');
  };

  const handleEditNamespace = namespace => {
    setNamespaceToEdit({ ...namespace, identity: namespace.identity });
    setIsUpdateModalOpen(true);
  };

  const handleNamespaceUpdate = async updatedNamespaceData => {
    await onNamespaceUpdated(updatedNamespaceData);
    setIsUpdateModalOpen(false);
  };

  if (!namespaces || namespaces.length === 0) {
    return <p className="text-xs mt-3 dark:text-white">No namespaces found.</p>;
  }

  return (
    <div className="w-full overflow-x-auto mt-8">
      <div>
        <button
          className="flex flex-row items-center bg-customBlue hover:bg-gray-900 hover:shadow-lg text-white text-xs font-semibold py-2 px-4 rounded-md mb-3 dark:bg-navy-600 dark:hover:bg-navy-700"
          onClick={onAddNamespace}
        >
          <FaPlus className="mr-2" />
          Add Namespace
        </button>
      </div>
      <table className="min-w-full divide-y divide-gray-200 mt-4">
        <thead className="bg-gray-400 dark:bg-navy-700">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
              Name
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
              Description
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
              Created At
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
              Actions
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200 dark:bg-navy-700">
          {namespaces.map((namespace, index) => (
            <tr
              key={`namespace-${index}`}
              className="transition hover:bg-gray-50 dark:hover:bg-navy-600"
            >
              <td className="px-6 py-4 text-sm whitespace-nowrap dark:text-white">
                {namespace.name}
              </td>
              <td className="px-6 py-4 text-sm whitespace-nowrap dark:text-white">
                {namespace.description}
              </td>
              <td className="px-6 py-4 text-sm whitespace-nowrap dark:text-white">
                {formatDateTime(namespace.createdAt)}
              </td>
              <td className="px-6 py-4 whitespace-nowrap dark:text-white">
                <button
                  onClick={() => toggleMenu(namespace.identity)}
                  className="text-gray-500 hover:text-gray-700 ml-2"
                >
                  <FaEllipsisV />
                </button>
                {openMenu === namespace.identity && (
                  <div
                    ref={menuRef}
                    className="absolute right-4 mt-2 w-48 bg-white shadow-lg rounded-lg dark:bg-navy-700"
                  >
                    <ul className="py-1">
                      <li>
                        <a
                          href="#permissions"
                          className="block px-6 py-2 text-sm text-gray-700 hover:bg-gray-50 dark:hover:bg-navy-600 dark:text-gray-300"
                          onClick={() => onSelectNamespace(namespace)}
                        >
                          Select
                        </a>
                      </li>
                      <li>
                        <a
                          href="#edit"
                          className="block px-6 py-2 text-sm text-gray-700 hover:bg-gray-50 dark:hover:bg-navy-600 dark:text-gray-300"
                          onClick={() => handleEditNamespace(namespace)}
                        >
                          Edit
                        </a>
                      </li>
                      <li className="border-t border-gray-200">
                        <a
                          href="#leave"
                          className="block px-6 py-2 text-sm text-red-600 hover:bg-gray-50 dark:text-red-500 dark:hover:bg-navy-600"
                          onClick={() => {
                            setNamespaceToRemove(namespace);
                            setShowConfirmModal(true);
                          }}
                        >
                          Delete
                        </a>
                      </li>
                    </ul>
                  </div>
                )}
                {/* Confirm deletion modal */}
                {showConfirmModal && (
                  <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
                    <div className="bg-white p-8 rounded-lg shadow-lg w-[33rem] h-[20rem]">
                      <div className="flex justify-between items-center border-b pb-2 mb-4">
                        <h3 className="text-lg font-bold">Remove Namespace</h3>
                        <button
                          onClick={handleCancelRemove}
                          className="text-gray-500 hover:text-gray-800"
                        >
                          <svg
                            className="w-6 h-6"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M6 18L18 6M6 6l12 12"
                            ></path>
                          </svg>
                        </button>
                      </div>
                      <p>Do you want to remove {namespaceToRemove?.name}?</p>
                      <input
                        type="text"
                        placeholder="Type 'confirm' to confirm"
                        className="border border-gray-300 p-2 mt-12 w-full"
                        value={confirmText}
                        onChange={e => setConfirmText(e.target.value)}
                      />
                      <div className="flex justify-end mt-10">
                        <button
                          onClick={handleCancelRemove}
                          className="bg-gray-500 text-white px-4 py-2 rounded-md mr-2"
                        >
                          Cancel
                        </button>
                        <button
                          onClick={handleConfirmRemove}
                          className="bg-red-500 text-white px-4 py-2 rounded-md"
                        >
                          Confirm
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {isUpdateModalOpen && (
        <UpdateNamespaceModal
          isOpen={isUpdateModalOpen}
          onClose={() => setIsUpdateModalOpen(false)}
          organizationId={organizationId}
          tenantId={tenantId}
          namespace={namespaceToEdit}
          onUpdate={handleNamespaceUpdate}
          onNamespaceUpdated={handleNamespaceUpdate}
        />
      )}
    </div>
  );
};

export default NamespacesList;
