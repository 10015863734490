import React, { useEffect, useState, useRef } from 'react';
import { FaPlus, FaEllipsisV } from 'react-icons/fa';
import { formatDateTime } from 'utilities/formatDateTime';
import {
  getOrganisationMemberships,
  deleteOrganisation,
} from 'services/api/organizationService';
import Loading from 'views/loading/Loading';
import RoleBadge from 'utilities/RoleBadge';
import CreateOrganization from './CreateOrganisation';
import UpdateOrganization from './UpdateOrganization';
import { toast } from 'react-toastify';

function OrganizationList() {
  const [memberships, setMemberships] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [openMenu, setOpenMenu] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const menuRef = useRef(null);

  useEffect(() => {
    const fetchMemberships = async () => {
      try {
        const response = await getOrganisationMemberships();
        if (response) {
          setMemberships(response);
        } else {
          setError('Failed to fetch organisations.');
        }
      } catch (err) {
        setError(err.response?.data?.message || 'An unexpected error occurred');
        console.error('Error fetching organisations:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchMemberships();
  }, []);

  const handleDelete = async identity => {
    try {
      const response = await deleteOrganisation(identity);
      if (response.status === 204) {
        const updatedMemberships = memberships.filter(
          membership => membership.identity !== identity,
        );
        toast.success('Organisation deleted successfully', {
          autoClose: 3000,
          position: 'top-center',
        });
        setMemberships(updatedMemberships);
      } else {
        setError('Failed to delete organisation.');
        toast.error('Failed to delete organisation', {
          autoClose: 3000,
          position: 'top-center',
        });
      }
    } catch (err) {
      setError(err.response?.data?.message || 'An error occurred');
      console.error('Error deleting or:', err);
    }
  };

  const toggleMenu = identity => {
    setOpenMenu(openMenu === identity ? null : identity);
  };

  const handleEditOrganizationClick = organization => {
    setSelectedOrganization(organization);
    setIsUpdateModalOpen(true);
  };

  useEffect(() => {
    const handleClickOutside = event => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setOpenMenu(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleAddOrganizationClick = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleModalSuccess = () => {
    setIsModalOpen(false);
  };

  const handleUpdateSuccess = async () => {
    //await fetchMemberships(); // Refresh the organization list after update
    setIsUpdateModalOpen(false);
  };

  if (loading) return <Loading />;

  return (
    <div className="max-w-8xl mx-auto  mt-12">
      <div className="flex flex-col justify-between lg:flex-row">
        <h1 className="text-sm font-bold mb-2 text-gray-800 mt-6 lg:text-lg  dark:text-white">
          {memberships.length === 1
            ? 'You are a member of the following organization'
            : `You are a member of ${memberships.length} organizations`}
        </h1>
        <div>
          <button
            className="flex flex-row items-center mt-6 bg-customBlue hover:bg-gray-900 hover:shadow-lg text-white text-xs font-semibold py-2 px-4 rounded-md lg:text-sm dark:bg-navy-600 dark:hover:bg-navy-700"
            onClick={handleAddOrganizationClick}
          >
            <FaPlus className="mr-2" />
            Add Organization
          </button>
        </div>
      </div>
      {error && <p className="text-red-600 text-center mb-4">{error}</p>}

      {memberships.length > 0 ? (
        <div className="overflow-x-auto mt-4">
          <table className="min-w-full divide-y divide-gray-200 bg-white">
            <thead className="bg-gray-400 dark:bg-navy-700">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                  Organization
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                  Slug
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                  Role
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-white uppercase tracking-wider">
                  Created At
                </th>
                <th className="relative px-6 py-3 ">
                  <span className="sr-only ">Actions</span>
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 dark:bg-navy-700">
              {memberships.map(membership => (
                <tr
                  key={membership.identity}
                  className="hover:bg-gray-50 transition dark:hover:bg-navy-600"
                >
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm font-medium text-gray-900 dark:text-white">
                      {membership.organisation?.name}
                    </div>
                  </td>
                  <td className="px-6 py-4 text-sm font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    {membership.organisation?.slug}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <RoleBadge role={membership.role} size="sm" />
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium dark:text-white">
                    {formatDateTime(membership.createdAt)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                    <button
                      onClick={() => toggleMenu(membership.identity)}
                      className="text-gray-500 hover:text-gray-700 ml-2"
                    >
                      <FaEllipsisV />
                    </button>
                    {openMenu === membership.identity && (
                      <div
                        ref={menuRef}
                        className="absolute right-4 mt-2 w-48 bg-white shadow-lg rounded-lg  dark:bg-navy-700"
                      >
                        <ul className="py-1 ">
                          <li>
                            <a
                              href="#permissions"
                              className="block px-6 py-2 text-sm text-gray-700 hover:bg-gray-50 dark:hover:bg-navy-600 dark:text-gray-300"
                            >
                              Permissions
                            </a>
                          </li>
                          <li>
                            <a
                              href="#permissions"
                              className="block px-6 py-2 text-sm text-gray-700 hover:bg-gray-50 dark:hover:bg-navy-600 dark:text-gray-300"
                              onClick={() =>
                                handleEditOrganizationClick(
                                  membership.organisation,
                                )
                              }
                            >
                              Edit
                            </a>
                          </li>
                          <li className="border-t border-gray-200">
                            <a
                              href="#leave"
                              className="block px-6 py-2 text-sm text-red-600 hover:bg-gray-50 dark:text-red-500 dark:hover:bg-navy-600"
                              onClick={() => handleDelete(membership.identity)}
                            >
                              Leave
                            </a>
                          </li>
                        </ul>
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p className="text-center text-gray-600">No organisation found.</p>
      )}

      {/* Modal for creating organization */}
      {isModalOpen && (
        <CreateOrganization
          onClose={handleModalClose}
          onSuccess={handleModalSuccess}
        />
      )}

      {isUpdateModalOpen && selectedOrganization && (
        <UpdateOrganization
          isOpen={isUpdateModalOpen}
          onClose={() => setIsUpdateModalOpen(false)}
          onSuccess={handleUpdateSuccess}
          organization={selectedOrganization}
        />
      )}
    </div>
  );
}

export default OrganizationList;
