import React from 'react';

const getRoleClass = (role) => {
  role = role.toLowerCase();
  switch (role) {
    case 'admin':
      return 'bg-red-400 text-white'; 
    case 'owner':
      return 'bg-blue-400 text-white'; 
    case 'member':
      return 'bg-green-400 text-white';
    case 'viewer':
      return 'bg-yellow-400 text-white'; 
    default:
      return 'bg-gray-200 text-gray-800'; 
  }
};

const RoleBadge = ({ role, size }) => {
  const roleClass = getRoleClass(role);
  const textSize = size === 'sm' ? 'text-[8px]' : size === 'lg' ? 'text-[12px]' : 'text-[10px]';
  const paddingSize = size === 'sm' ? 'px-1 py-1' : size === 'lg' ? 'px-2 py-2' : 'px-3 py-1.5';

  return (
    <p className={`font-semibold mt-1 inline-block rounded-full ${roleClass} ${textSize} ${paddingSize}`}>
      {role}
    </p>
  );
};

export default RoleBadge;