import React, { useState } from 'react';
import { toast } from 'react-toastify';
import yaml from 'js-yaml';
import { sendYaml } from 'services/api/tenantService';

const SendYaml = () => {
  const [yamlContent, setYamlContent] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const convertYamlToJson = yamlContent => {
    return yaml.load(yamlContent);
  };

  const handleYamlChange = event => {
    setYamlContent(event.target.value);
  };

  const handleSubmit = async event => {
    event.preventDefault();
    setLoading(true);
    setError('');

    try {
      const jsonData = convertYamlToJson(yamlContent);

      await sendYaml(jsonData);

      toast.success('YAML sent successfully!', {
        autoClose: 3000,
        position: 'top-center',
      });
      setYamlContent('');
    } catch (err) {
      setError('Failed to send YAML. Please try again.');
      toast.error(error || 'Failed to send YAML. Please try again.', {
        autoClose: 3000,
        position: 'top-center',
      });
      console.error(err);
      toast.error(error || 'An unexpected error occurred.', {
        autoClose: 3000,
        position: 'top-center',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="bg-white shadow-md rounded-lg p-6 dark:bg-navy-700 dark:text-white">
      <h2 className="text-lg font-semibold mb-4">Send YAML File</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label
            htmlFor="yamlInput"
            className="block text-sm font-semibold mb-2 text-gray-700 dark:text-white"
          >
            Paste your YAML content here
          </label>
          <textarea
            id="yamlInput"
            rows="10"
            value={yamlContent}
            onChange={handleYamlChange}
            className="w-full p-3 border border-gray-300 rounded-md  dark:bg-navy-700 dark:text-white "
            placeholder="Enter YAML content here..."
            style={{ resize: 'none' }}
            required
          />
        </div>

        {error && <p className="text-red-600 mb-4">{error}</p>}

        <div className="flex justify-end">
          <button
            type="submit"
            disabled={loading}
            className={`bg-customBlue hover:bg-gray-800 text-white font-semibold py-2 px-4 rounded-md dark:bg-navy-600 dark:hover:bg-navy-700 ${
              loading ? 'opacity-50 cursor-not-allowed' : ''
            }`}
          >
            {loading ? 'Sending...' : 'Send YAML'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default SendYaml;
