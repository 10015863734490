import React, { useState, useEffect } from 'react';
import { createTenant } from 'services/api/tenantService';
import { getClouds } from 'services/api/cloudService';
import { toast } from 'react-toastify';
import CloudIcon from 'utilities/CloudIcon';
import { createUserHostCluster } from 'services/api/hostclusterService';

function CreateTenant({ organizationId, onCreate, onCancel }) {
  //const [tenant, setTenant] = useState({ name: '', description: '' });
  const [clouds, setClouds] = useState([]);
  const [selectedCloud, setSelectedCloud] = useState(null);
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    const fetchClouds = async () => {
      try {
        const data = await getClouds();
        setClouds(data);
      } catch (err) {
        setError('Failed to fetch cloud data. Please try again later.');
        console.error(err);
      }
    };

    fetchClouds();
  }, []);

  // const handleInputChange = event => {
  //   const { name, value } = event.target;
  //   setTenant(prevTenant => ({ ...prevTenant, [name]: value }));
  // };

  const handleCloudSelect = cloud => {
    setSelectedCloud(cloud);
    setSelectedRegion(null);
  };

  const handleRegionChange = event => {
    const selectedRegionIdentity = event.target.value;
    const region = selectedCloud.regions.find(
      r => r.identity === selectedRegionIdentity,
    );
    setSelectedRegion(region);
  };

  const handleSubmit = async event => {
    event.preventDefault();

    try {
      if (selectedCloud?.cloudProviderType === 'azure') {
        // For Azure, use createUserHostCluster directly
        const response = await createUserHostCluster(organizationId);

        toast.success('Azure provisioned successfully.', {
          autoClose: 3000,
          position: 'top-center',
        });

        setSuccess('Azure provisioned successfully!');
        setError('');
        onCreate(response.data);
      } else {
        // For other cloud providers, create tenant as before
        const response = await createTenant(
          {
            cloudId: selectedCloud?.identity,
            regionId: selectedRegion?.identity,
          },
          organizationId,
        );

        const createdTenantId = response.data.identity;

        if (createdTenantId) {
          toast.success('Tenant created successfully.', {
            autoClose: 3000,
            position: 'top-center',
          });
          setSuccess('Tenant created successfully!');
          setError('');
          onCreate(response.data);
        } else {
          setError('Tenant creation failed. No tenant ID returned.');
        }
      }
    } catch (err) {
      if (err.response) {
        setError(
          err.response.data.message ||
            'An error occurred while processing your request.',
        );
        toast.error(
          err.response.data.message ||
            'An error occurred while processing your request.',
          { autoClose: 3000, position: 'top-center' },
        );
      } else if (err.request) {
        setError('No response received from the server.');
      } else {
        setError('An unexpected error occurred.');
      }
      setSuccess('');
      console.error(err);
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center overflow-auto bg-gray-900 bg-opacity-50">
      <div className="bg-white shadow-lg rounded-lg  max-w-sm p-1 relative lg:max-w-3xl dark:bg-navy-700">
        <div className="card-body p-7">
          {/* Custom Cloud Selection List */}
          <div className="mb-4">
            <label className="block text-sm text-gray-700 font-semibold mb-2 dark:text-white">
              Select Cloud Provider
            </label>
            <div className="flex flex-wrap gap-4">
              {clouds.map(cloud => (
                <div
                  key={cloud.identity}
                  className={`cursor-pointer p-4 border rounded-lg flex items-center space-x-3 ${selectedCloud?.identity === cloud.identity ? 'border-blue-500 bg-gray-200' : 'border-gray-300 dark:bg-navy-700 dark:border-navy-500'}`}
                  onClick={() => handleCloudSelect(cloud)}
                >
                  <CloudIcon
                    cloudProviderType={cloud.cloudProviderType}
                    className="w-6 h-6"
                  />
                  <span className="text-sm font-semibold dark:text-white">
                    {cloud.name}
                  </span>
                </div>
              ))}
            </div>
          </div>

          {/* Region Selection Dropdown */}
          {selectedCloud && (
            <div className="mb-4">
              <label
                className="block text-sm text-gray-700 font-semibold mb-2 dark:text-white"
                htmlFor="region"
              >
                Select Region
              </label>
              <select
                id="region"
                className="w-full p-3 border border-gray-300 rounded-md dark:bg-navy-700 dark:text-white"
                value={selectedRegion?.identity || ''}
                onChange={handleRegionChange}
                disabled={!selectedCloud}
              >
                <option value="" disabled>
                  Select a region
                </option>
                {selectedCloud.regions.map(region => (
                  <option key={region.identity} value={region.identity}>
                    {region.name}
                  </option>
                ))}
              </select>
            </div>
          )}

          {/* Tenant Details Form */}
          <div className="mt-6">
            <form onSubmit={handleSubmit}>
              {/* <div className="mb-4">
                <label
                  className="block text-sm text-gray-700 font-semibold mb-2 dark:text-white"
                  htmlFor="name"
                >
                  Name (Optional)
                </label>
                <input
                  className="w-full p-3 border border-gray-300 rounded-md dark:bg-navy-700 dark:text-white"
                  id="name"
                  name="name"
                  type="text"
                  value={tenant.name}
                  onChange={handleInputChange}
                  placeholder="Enter tenant name here"
                  
                />
              </div> */}

              {/* <div className="mb-4">
                <label
                  className="block text-sm text-gray-700 font-semibold mb-2 dark:text-white"
                  htmlFor="description"
                >
                  Description (Optional)
                </label>
                <textarea
                  className="w-full p-3 border border-gray-300 rounded-md dark:bg-navy-700 dark:text-white"
                  id="description"
                  name="description"
                  value={tenant.description}
                  onChange={handleInputChange}
                  placeholder="Enter description here"
                  style={{ resize: 'none' }}
                />
              </div> */}

              {error && <p className="text-red-600">{error}</p>}
              {success && <p className="text-green-600">{success}</p>}

              <div className="flex justify-end gap-3 mt-4">
                <button
                  type="button"
                  className="bg-white hover:bg-gray-800 text-gray-900 text-xs hover:text-white shadow-md font-semibold px-4 py-2 rounded-md lg:text-sm dark:bg-navy-600 dark:text-white dark:hover:bg-navy-500 dark:hover:bg-navy-700"
                  onClick={onCancel}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="bg-customBlue hover:bg-gray-900  hover:shadow-lg text-white text-xs font-semibold py-2 px-4 rounded-md lg:text-sm dark:bg-navy-600 dark:hover:bg-navy-600  dark:hover:bg-navy-700"
                >
                  Create Tenant
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateTenant;
