import { useEffect, useState } from 'react';
import WidgetCard from 'components/widget/WidgetCard';
import { getOrganisationMemberships } from 'services/api/organizationService';
import { getTenants } from 'services/api/tenantService';
import TenantStatus from 'utilities/TenantStatus';

const TenantCard = () => {
  const [memberships, setMemberships] = useState([]);
  const [tenant, setTenant] = useState([]);
  const [selectedOrgId, setSelectedOrgId] = useState(null);

  useEffect(() => {
    const getOrganization = async () => {
      try {
        const response = await getOrganisationMemberships();
        setMemberships(response);
        if (response.length > 0) {
          setSelectedOrgId(response[0].organisation.identity);
        }
      } catch (error) {
        console.error('Failed to fetch organization memberships', error);
      }
    };

    getOrganization();
  }, []);

  useEffect(() => {
    if (selectedOrgId) {
      const getTenantInfo = async () => {
        try {
          const response = await getTenants(selectedOrgId);
          setTenant(response);
        } catch (error) {
          console.error('Failed to fetch tenant info', error);
        }
      };

      getTenantInfo();
    }
  }, [selectedOrgId]);

  return (
    <WidgetCard
      title={
        <h1 className="">
          {tenant.length === 1
            ? 'You have an tenant'
            : `You have ${tenant.length} tenants`}
        </h1>
      }
    >
      {/* Organization Selector */}
      <div className="mb-4">
        <label
          htmlFor="organization-select"
          className="block text-xs font-medium text-gray-700 dark:text-gray-300"
        >
          Select Organization
        </label>
        <select
          id="organization-select"
          value={selectedOrgId || ''}
          onChange={e => setSelectedOrgId(e.target.value)}
          className="mt-1 block w-full p-1 text-xs border border-gray-300 rounded-md dark:border-navy-600 dark:bg-navy-700 dark:text-white focus:outline-none focus:ring focus:ring-blue-500"
        >
          {memberships.map(membership => (
            <option
              key={membership.organisation.identity}
              value={membership.organisation.identity}
            >
              {membership.organisation.name}
            </option>
          ))}
        </select>
      </div>

      {/* Tenants List */}
      <div className="overflow-x-auto">
        <table className="min-w-full border border-gray-200  divide-y divide-gray-300 dark:border-navy-600 dark:divide-navy-600">
          <thead className="bg-gray-100 dark:bg-navy-800">
            <tr>
              <th className="px-2 py-2 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                Tenant Name
              </th>
              <th className="px-2 py-2 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                Cloud Region
              </th>
              <th className="px-2 py-2 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                Status
              </th>
            </tr>
          </thead>
          <tbody className="bg-white dark:bg-navy-700 divide-y divide-gray-300 dark:divide-navy-600">
            {tenant.length > 0 ? (
              tenant.map((ten, index) => (
                <tr
                  key={index}
                  className="hover:bg-gray-50 dark:hover:bg-navy-600"
                >
                  <td className="px-2 py-1 whitespace-nowrap text-xs text-gray-900 dark:text-white">
                    {ten.name || `Tenant ${index + 1}`}
                  </td>
                  <td className="px-2 py-1 whitespace-nowrap text-xs text-gray-500 dark:text-gray-400">
                    {ten.cloudRegion.name || 'No region available.'}
                  </td>
                  <td className="px-2 py-1 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                    <TenantStatus status={ten.status} size="sm" />
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan="3"
                  className="px-4 py-2 text-xs text-center text-gray-500 dark:text-gray-400"
                >
                  No tenants available.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </WidgetCard>
  );
};

export default TenantCard;
