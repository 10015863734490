const WidgetCard = ({ title, children }) => {
  return (
    <section className="w-full max-w-lg bg-white shadow-lg rounded-lg p-4 dark:bg-navy-700">
      <h2 className="text-sm font-bold text-gray-800  mb-6 dark:text-white">
        {title}
      </h2>
      {children}
    </section>
  );
};

export default WidgetCard;
