/* eslint-disable */

import { HiX } from 'react-icons/hi';
import Links from './components/Links';
import KubeIcon from 'assets/img/kube.jpeg';
import { useNavigate } from 'react-router-dom';

import SidebarCard from 'components/sidebar/componentsrtl/SidebarCard';
import routes from 'routes.js';

const Sidebar = ({ open, onClose }) => {
  const navigate = useNavigate();
  const handleLogoClick = () => {
    navigate('/');
  };

  return (
    <div
      className={`sm:none duration-175 linear fixed !z-50 flex min-h-full flex-col bg-white pb-10 shadow-2xl shadow-white/5 transition-all dark:!bg-navy-800 dark:text-white md:!z-50 lg:!z-50 xl:!z-0 ${
        open ? 'translate-x-0' : '-translate-x-96'
      }`}
    >
      <span
        className="absolute top-4 right-4 block cursor-pointer xl:hidden"
        onClick={onClose}
      >
        <HiX />
      </span>

      <div
        className={`mx-[56px] mt-[50px] flex flex-col cursor-pointer items-center`}
        onClick={handleLogoClick}
      >
        <img
          src={KubeIcon}
          alt="Kube Icon"
          className="w-10 h-10 rounded-full"
        />
        <div className="mt-1 ml-1 h-2.5 font-poppins text-lg font-bold text-navy-700 lg:text-[23px] dark:text-white">
          Kubespaces.io
        </div>
      </div>
      <div className="mt-[58px] mb-7 h-px bg-gray-300 dark:bg-white/30" />
      {/* Nav item */}

      <ul className="mb-auto pt-1">
        <Links routes={routes} onClose={onClose} />
      </ul>

      {/* Free Horizon Card */}
      <div className="flex justify-center">
        <SidebarCard />
      </div>

      {/* Nav item end */}
    </div>
  );
};

export default Sidebar;
