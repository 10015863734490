import Keycloak from 'keycloak-js';

// Define Keycloak configuration object
const keycloakConfig = {
  url: process.env.REACT_APP_KEYCLOAK_URL,
  realm: process.env.REACT_APP_KEYCLOAK_REALM,
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
};

// Initialize Keycloak instance
const keycloak = new Keycloak(keycloakConfig);

// Function to fetch GitHub avatar URL (or any other external source)
const fetchGithubAvatarUrl = async (username) => {
  try {
    // Check if the username is an email address
    if (username.includes('@')) {
      // Try to extract the GitHub username from the email address
      const githubUsername = username.split('@')[0];
      const response = await fetch(`https://api.github.com/users/${githubUsername}`);
      const data = await response.json();
      return data.avatar_url;
    } else {
      // If the username is not an email address, use it as is
      const response = await fetch(`https://api.github.com/users/${username}`);
      const data = await response.json();
      return data.avatar_url;
    }
  } catch (error) {
    console.error("Failed to fetch GitHub avatar URL:", error);
    return null;
  }
};

const initKeycloak = async (onAuthenticatedCallback) => {
  try {
    const authenticated = await keycloak.init({
      onLoad: 'check-sso',
      silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
      checkLoginIframe: false, // Disable login iframe
    });

    console.log("Is user authenticated: ", authenticated);

    if (authenticated) {
      const githubUsername = keycloak.tokenParsed.preferred_username;
      const githubAvatarUrl = await fetchGithubAvatarUrl(githubUsername);

      // Get the Google profile picture URL directly from the token
      const googleProfilePhotoUrl = keycloak.tokenParsed.picture; 
      await KeycloakService.updateUserProfile(keycloak.tokenParsed.sub, githubAvatarUrl, googleProfilePhotoUrl);
    }
    
    onAuthenticatedCallback(authenticated);
  } catch (error) {
    console.error("Keycloak initialization failed:", error);
    onAuthenticatedCallback(false);
  }
};

const KeycloakService = {
  keycloak,
  initKeycloak,
  doLogin: () => keycloak.login(),
  doLogout: () => keycloak.logout(),
  isLoggedIn: () => !!keycloak.token,
  getToken: () => keycloak.token,
  updateToken: async (minValidity = 5) => {
    try {
      const refreshed = await keycloak.updateToken(minValidity);
      if (refreshed) {
        console.log('Token refreshed');
      } else {
        console.log('Token is still valid');
      }
      return keycloak.token;
    } catch (error) {
      console.error('Failed to refresh token', error);
      throw error;
    }
  },
  getUsername: () => keycloak.tokenParsed?.preferred_username,
  hasRole: (roles) => roles.some(role => keycloak.hasRealmRole(role)),
  async getUserProfile() {
    try {
      const userProfile = await keycloak.loadUserProfile();
      return {
        githubAvatarUrl: userProfile.attributes?.github_avatar_url,
        googleProfilePhotoUrl: userProfile.attributes?.picture, 
      };
    } catch (error) {
      console.error("Failed to load user profile:", error);
      return {
        githubAvatarUrl: null,
        googleProfilePhotoUrl: null,
      };
    }
  },
  async updateUserProfile(userId, githubAvatarUrl, googleProfilePhotoUrl) {
    const userProfile = await keycloak.loadUserProfile();
    const attributes = userProfile.attributes || {};
    
    // Update or set the avatar URL
    if (githubAvatarUrl) {
      attributes.github_avatar_url = githubAvatarUrl;
    }
    if (googleProfilePhotoUrl) {
      attributes.picture = googleProfilePhotoUrl; 
    }
    // Save updated attributes back to Keycloak
    await keycloak.updateUserProfile({ attributes });
  }
};

export default KeycloakService;
