import React, { useEffect, useState, useRef } from 'react';
import {
  getInvites,
  resendInvite,
  cancelInvite,
} from 'services/api/inviteService';
import { formatDateTime } from 'utilities/formatDateTime';
import { FaEllipsisV } from 'react-icons/fa';
import RoleBadge from 'utilities/RoleBadge';
import Loading from 'views/loading/Loading';
import { toast } from 'react-toastify';

const GetInvites = ({ onInviteAccepted, slug }) => {
  const [invites, setInvites] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [inviteToCancel, setInviteToCancel] = useState(null);
  const [confirmText, setConfirmText] = useState('');
  const [openMenu, setOpenMenu] = useState(null);
  const menuRef = useRef(null);

  const toggleMenu = namespaceId => {
    setOpenMenu(openMenu === namespaceId ? null : namespaceId);
  };

  useEffect(() => {
    const handleClickOutside = event => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setOpenMenu(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const fetchInvites = async () => {
      try {
        const data = await getInvites(slug);
        setInvites(data);
      } catch (error) {
        setError('Failed to load invites.');
        console.error('Error fetching invites:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchInvites();
  }, [slug]);

  const handleResendInvite = async inviteCode => {
    try {
      await resendInvite(inviteCode, slug);
      setInvites(
        invites.map(invite =>
          invite.inviteCode === inviteCode
            ? { ...invite, status: 'resent' }
            : invite,
        ),
      );
      toast.success('Invite resent successfully.', {
        autoClose: 3000,
        position: 'top-center',
      });
    } catch (error) {
      setError('Failed to resend invite.');
      toast.error('Failed to resend invite.');
      console.error('Error resending invite:', error);
    }
  };

  const handleCancelClick = invite => {
    setInviteToCancel(invite);
    setShowConfirmModal(true);
  };

  const handleConfirmCancel = async () => {
    if (inviteToCancel && confirmText === 'confirm') {
      try {
        if (inviteToCancel.inviteCode) {
          await cancelInvite(inviteToCancel.inviteCode, slug);
          setInvites(prevInvites =>
            prevInvites.filter(
              invite => invite.inviteCode !== inviteToCancel.inviteCode,
            ),
          );
          setShowConfirmModal(false);
          setInviteToCancel(null);
          setConfirmText('');
          toast.success('Invite canceled successfully.', {
            autoClose: 3000,
            position: 'top-center',
          });
        } else {
          console.error('Invite code is missing.');
        }
      } catch (error) {
        setError(`Failed to cancel invite: ${error.message}`);
        toast.error('Failed to cancel invite.');
        console.error('Error canceling invite:', error);
      }
    } else {
      console.error(
        'Confirmation text does not match or invite code is missing.',
      );
    }
  };

  const handleCancelConfirm = () => {
    setShowConfirmModal(false);
    setInviteToCancel(null);
    setConfirmText('');
  };

  return (
    <div className="">
      <h2 className="text-sm font-bold mb-4 lg:text-lg dark:text-white">
        Pending Invites
      </h2>
      {loading ? (
        <Loading />
      ) : error ? (
        <p className="text-red-500">{error}</p>
      ) : invites.length > 0 ? (
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-400 dark:bg-navy-700">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                  Email
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                  Invited At
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                  Expires At
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                  Role
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200 dark:bg-navy-700">
              {invites.map(invite => (
                <tr
                  key={invite.inviteCode}
                  className="hover:bg-gray-50 transition dark:hover:bg-navy-600"
                >
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-white">
                    {invite.email}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-white">
                    {formatDateTime(invite.createdAt)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-white">
                    {formatDateTime(invite.expiresAt)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-white">
                    <RoleBadge role={invite.role} size="sm" />{' '}
                    {/* Use RoleBadge component here */}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                    <button
                      onClick={() => toggleMenu(invite.inviteCode)}
                      className="text-gray-500 hover:text-gray-700 ml-2"
                    >
                      <FaEllipsisV />
                    </button>
                    {openMenu === invite.inviteCode && (
                      <div
                        ref={menuRef}
                        className="absolute right-4 mt-2 w-48 bg-white shadow-lg rounded-lg dark:bg-navy-700"
                      >
                        <ul className="py-1">
                          <li>
                            <a
                              href="#edit"
                              className="block px-6 py-2 text-sm text-gray-700 hover:bg-gray-50 dark:hover:bg-navy-600 dark:text-gray-300"
                              onClick={() =>
                                handleResendInvite(invite.inviteCode)
                              }
                            >
                              Resend
                            </a>
                          </li>
                          <li className="border-t border-gray-200">
                            <a
                              href="#leave"
                              className="block px-6 py-2 text-sm text-red-600 hover:bg-gray-50 dark:text-red-500 dark:hover:bg-navy-600"
                              onClick={() => {
                                handleCancelClick(invite);
                              }}
                            >
                              Cancel
                            </a>
                          </li>
                        </ul>
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p className="text-sm lg:text-md dark:text-white">
          No invites available to manage.
        </p>
      )}

      {showConfirmModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="bg-white p-8 rounded-lg shadow-lg w-[33rem] h-[20rem] dark:bg-navy-700">
            <div className="flex justify-between items-center border-b pb-2 mb-4">
              <h3 className="text-lg font-bold dark:text-white">
                Cancel Invite
              </h3>
              <button
                onClick={handleCancelConfirm}
                className="text-gray-500 hover:text-gray-800"
              >
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              </button>
            </div>
            <p className="dark:text-white">
              Do you want to cancel the invite to {inviteToCancel?.email}?
            </p>
            <input
              type="text"
              placeholder="Type 'confirm' to confirm"
              className="border border-gray-300 p-2 mt-12 w-full dark:bg-navy-600 dark:text-white"
              value={confirmText}
              onChange={e => setConfirmText(e.target.value)}
            />
            <div className="flex justify-end mt-10">
              <button
                onClick={handleCancelConfirm}
                className="bg-gray-500 text-white px-4 py-2 rounded-md mr-2 dark:bg-navy-600 dark:hover:bg-bg-700"
              >
                Cancel
              </button>
              <button
                onClick={handleConfirmCancel}
                className="bg-red-500 text-white px-4 py-2 rounded-md dark:bg-navy-600 dark:hover:bg-navy-700"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default GetInvites;
