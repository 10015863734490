import React, { useState, useEffect } from 'react';
import {
  FaUsers,
  FaEnvelope,
  FaUsersCog,
  FaHistory,
  FaKey,
  FaWallet,
} from 'react-icons/fa';
import Members from './components/members/Members';
import GetInvite from '../organisation/components/invite-member/GetInvite';
import {
  getOrganisationMemberships,
  getOrganisationMember,
} from 'services/api/organizationService';
import Teams from './components/teams/Teams';
import { FaBuilding, FaUserTie, FaTag } from 'react-icons/fa';
import Widget from 'components/widget/Widget';
import Loading from 'views/loading/Loading';
import { FiAlignJustify } from 'react-icons/fi';
import { HiX } from 'react-icons/hi';

const OrganisationMembers = () => {
  const [activeSection, setActiveSection] = useState('members');
  const [menuOpen, setMenuOpen] = useState(false);
  const [members, setMembers] = useState([]);
  const [invites, setInvites] = useState([]);
  const [organisations, setOrganisations] = useState([]);
  const [selectedOrganisation, setSelectedOrganisation] = useState(null);
  const [memberships, setMemberships] = useState(null);
  const [fetchedData, setFetchedData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchOrganisationData = async () => {
      try {
        const response = await getOrganisationMemberships();

        if (response && response.length > 0) {
          setFetchedData(response);
          const firstOrg = response[0];
          setOrganisations(response.map(org => org.organisation));
          setSelectedOrganisation(firstOrg.organisation);
          setMembers(firstOrg.members || []);
          setInvites(firstOrg.invites || []);
          setMemberships({ role: firstOrg.role });
        } else {
          console.error('Error: No organisation data found.');
        }
      } catch (error) {
        console.error('Error fetching organisation data:', error);
      }
    };

    fetchOrganisationData();
  }, []);

  useEffect(() => {
    const fetchMembers = async () => {
      if (!selectedOrganisation || !selectedOrganisation.identity) {
        console.error('Selected organisation is null or undefined.');
        return;
      }

      setLoading(true);
      try {
        const membersData = await getOrganisationMember(
          selectedOrganisation.identity,
        );
        if (membersData) {
          setMembers(membersData);
        } else {
          setMembers([]);
        }
      } catch (error) {
        console.error('Error fetching members:', error);
        setMembers([]);
      } finally {
        setLoading(false);
      }
    };

    if (selectedOrganisation && selectedOrganisation.identity) {
      fetchMembers();
    }
  }, [selectedOrganisation]);

  useEffect(() => {
    if (selectedOrganisation && organisations.length) {
      const selectedOrgData = organisations.find(
        org => org.identity === selectedOrganisation.identity,
      );

      if (selectedOrgData) {
        const relevantData = fetchedData.find(
          org => org.organisation.identity === selectedOrgData.identity,
        );
        if (relevantData) {
          setMembers(relevantData.members || []);
          setInvites(relevantData.invites || []);
          setMemberships({ role: relevantData.role });
        }
      }
    }
  }, [selectedOrganisation, organisations, fetchedData]);

  const handleInviteAccepted = inviteCode => {
    setInvites(invites.filter(invite => invite.code !== inviteCode));
  };

  const handleOrganisationChange = e => {
    const orgId = e.target.value;

    const selectedOrg = organisations.find(org => org.identity === orgId);

    if (selectedOrg) {
      setSelectedOrganisation(selectedOrg);

      const relevantData = fetchedData.find(
        org => org.organisation.identity === selectedOrg.identity,
      );
      if (relevantData) {
        setMembers(relevantData.members || []);
        setInvites(relevantData.invites || []);
        setMemberships({ role: relevantData.role });
      }
    }
  };

  const handleSectionClick = section => {
    setActiveSection(section);
    setMenuOpen(false);
  };

  const renderContent = () => {
    switch (activeSection) {
      case 'members':
        return (
          <Members
            slug={selectedOrganisation?.slug}
            members={members}
            onEdit={id => alert(`Edit member with id: ${id}`)}
            onRemove={id => {
              if (
                window.confirm('Are you sure you want to remove this member?')
              ) {
                setMembers(members.filter(member => member.id !== id));
                alert(`Removed member with id: ${id}`);
              }
            }}
          />
        );
      case 'invites':
        return (
          <GetInvite
            slug={selectedOrganisation?.slug || ''}
            invites={invites}
            onInviteAccepted={handleInviteAccepted}
          />
        );
      case 'teams':
        return (
          <Teams
            slug={selectedOrganisation?.slug || ''}
            key={selectedOrganisation?.slug || ''}
          />
        );
      case 'audit':
        return (
          <div className="text-sm lg:text-md dark:text-white">
            Audit content goes here.
          </div>
        );
      case 'api':
        return (
          <div className="text-sm lg:text-md dark:text-white">
            API content goes here.
          </div>
        );
      case 'billing':
        return (
          <div className="text-sm lg:text-md dark:text-white">
            Billing content goes here.
          </div>
        );
      default:
        return null;
    }
  };

  if (!selectedOrganisation || !fetchedData || fetchedData.length === 0) {
    return loading ? (
      <Loading />
    ) : (
      <div className="mt-14">No organisation data found.</div>
    );
  }

  return (
    <div className=" mt-14">
      {/* Organisation Widgets */}
      <div className="mt-10 grid grid-cols-1 gap-5 mb-6 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6">
        {selectedOrganisation && (
          <>
            <Widget
              icon={<FaBuilding className="h-7 w-7" />}
              title={'Organization'}
              subtitle={selectedOrganisation.name || 'N/A'}
            />
            <Widget
              icon={<FaUserTie className="h-6 w-6" />}
              title={'Role'}
              subtitle={memberships?.role || 'N/A'}
            />
            <Widget
              icon={<FaTag className="h-7 w-7" />}
              title={'OrgID'}
              subtitle={selectedOrganisation.slug || 'N/A'}
            />
          </>
        )}
      </div>
      <div className="flex flex-col">
        {/* Organisation Dropdown */}
        {organisations.length > 0 && (
          <div className="my-6">
            <label
              htmlFor="organisationDropdown"
              className="block text-sm font-medium text-gray-700"
            >
              Select an Organization
            </label>
            <select
              id="organisationDropdown"
              className="mt-2 block w-full p-2 text-sm border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 lg:w-[690px] dark:bg-navy-700 dark:border-gray-700 dark:text-white dark:focus:ring-navy-600 dark:focus:border-navy-600"
              onChange={handleOrganisationChange}
              value={selectedOrganisation?.identity || ''}
            >
              {organisations.map(org => (
                <option key={org.identity} value={org.identity}>
                  {org.name}
                </option>
              ))}
            </select>
          </div>
        )}

        <div className="flex flex-col">
          {/* Hamburger Button */}
          <div className="md:hidden flex justify-between items-center p-4">
            <h1 className="text-sm">{selectedOrganisation?.name}</h1>
            <button
              className="text-xl text-gray-600"
              onClick={() => setMenuOpen(!menuOpen)}
            >
              {menuOpen ? (
                <HiX className="h-4 w-4 text-gray-600 dark:text-white" />
              ) : (
                <FiAlignJustify />
              )}
            </button>
          </div>

          {/* Horizontal Menu */}
          <nav
            className={`${
              menuOpen ? 'block' : 'hidden'
            } md:flex md:flex-row space-x-3 border-b-2 border-gray-200 mb-4 dark:border-gray-700`}
          >
            <div
              className={`flex items-center cursor-pointer py-2 px-7 lg:px-4 ${
                activeSection === 'members'
                  ? 'text-blue-600 border-b-2 border-blue-600 dark:text-blue-300 dark:border-blue-300'
                  : 'text-gray-600'
              }`}
              onClick={() => handleSectionClick('members')}
            >
              <FaUsers className="mr-2" />
              <span>Members</span>
            </div>
            <div
              className={`flex items-center cursor-pointer py-2 px-4 ${
                activeSection === 'invites'
                  ? 'text-blue-600 border-b-2 border-blue-600 dark:text-blue-300 dark:border-blue-300'
                  : 'text-gray-600'
              }`}
              onClick={() => handleSectionClick('invites')}
            >
              <FaEnvelope className="mr-2" />
              <span>Invites</span>
            </div>
            <div
              className={`flex items-center cursor-pointer py-2 px-4 ${
                activeSection === 'teams'
                  ? 'text-blue-600 border-b-2 border-blue-600 dark:text-blue-300 dark:border-blue-300'
                  : 'text-gray-600'
              }`}
              onClick={() => handleSectionClick('teams')}
            >
              <FaUsersCog className="mr-2" />
              <span>Teams</span>
            </div>
            <div
              className={`flex items-center cursor-pointer py-2 px-4 ${
                activeSection === 'audit'
                  ? 'text-blue-600 border-b-2 border-blue-600 dark:text-blue-300 dark:border-blue-300'
                  : 'text-gray-600'
              }`}
              onClick={() => handleSectionClick('audit')}
            >
              <FaHistory className="mr-2" />
              <span>Audit</span>
            </div>
            <div
              className={`flex items-center cursor-pointer py-2 px-4 ${
                activeSection === 'api'
                  ? 'text-blue-600 border-b-2 border-blue-600 dark:text-blue-300 dark:border-blue-300'
                  : 'text-gray-600'
              }`}
              onClick={() => handleSectionClick('api')}
            >
              <FaKey className="mr-2" />
              <span>API Keys</span>
            </div>
            <div
              className={`flex items-center cursor-pointer py-2 px-4 ${
                activeSection === 'billing'
                  ? 'text-blue-600 border-b-2 border-blue-600 dark:text-blue-300 dark:border-blue-300'
                  : 'text-gray-600'
              }`}
              onClick={() => handleSectionClick('billing')}
            >
              <FaWallet className="mr-2" />
              <span>Billing</span>
            </div>
          </nav>

          {/* Content Area */}
          <div className="flex-1 p-6">{renderContent()}</div>
        </div>
      </div>
    </div>
  );
};

export default OrganisationMembers;
