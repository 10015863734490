import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { formatDateTime } from 'utilities/formatDateTime';
import { FaEnvelope, FaCalendarAlt, FaCrown } from 'react-icons/fa';
import KeycloakService from 'services/KeycloakService';
import CreateOrganization from './components/organisation/CreateOrganisation';
import AcceptInvite from './components/invite-member/AcceptInvite';
import OrganizationList from './components/organisation/OrganizationList';
import {
  getOrganisationMemberships,
  getOrganisationInvites,
  getOrganisationMember,
} from 'services/api/organizationService';
import Widget from 'components/widget/Widget';
import Loading from 'views/loading/Loading';

const Organisation = () => {
  const [memberships, setMemberships] = useState([]);
  const [invites, setInvites] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [members, setMembers] = useState([]);

  const isAuthenticated = KeycloakService.isLoggedIn();
  const location = useLocation();
  const inviteCode = new URLSearchParams(location.search).get('inviteCode');
  const organisationId =
    memberships && memberships.length > 0 && memberships[0].organisation
      ? memberships[0].organisation.identity
      : null;

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      try {
        const [membershipsData, invitesData] = await Promise.all([
          getOrganisationMemberships(),
          getOrganisationInvites(),
        ]);
        setMemberships(membershipsData || []);
        setInvites(invitesData || []);
      } catch (err) {
        setError('Failed to fetch data.');
        console.error('Error fetching data:', err);
      } finally {
        setLoading(false);
      }
    };

    if (isAuthenticated) {
      fetchData();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (organisationId) {
      const fetchMembers = async () => {
        setLoading(true);
        try {
          const members = await getOrganisationMember(organisationId);
          setMembers(members || []);
        } catch (error) {
          console.error('Error fetching members:', error);
        } finally {
          setLoading(false);
        }
      };
      fetchMembers();
    }
  }, [organisationId]);

  const handleInviteAccepted = () => {
    console.log('Invite accepted!');
  };

  const handleInviteRejected = () => {
    console.log('Invite rejected!');
  };

  const closeModal = () => setIsModalOpen(false);

  const refreshData = async () => {
    setLoading(true);
    try {
      const membershipsData = await getOrganisationMemberships();
      setMemberships(membershipsData || []);
    } catch (err) {
      setError('Failed to refresh organization data.');
      console.error('Error refreshing data:', err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-8xl mx-auto mt-12">
      {/* Error State */}
      {error && (
        <div className="bg-red-100 text-red-700 p-5 rounded-lg mb-4">
          <p>{error}</p>
        </div>
      )}

      {/* Loading state */}
      {loading ? (
        <Loading />
      ) : (
        <>
          {/* Members Widget */}
          {members.length > 0 && (
            <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6">
              <Widget
                icon={<FaCrown className="h-7 w-7" />}
                title={'Owner'}
                subtitle={members[0]?.user?.displayName || 'N/A'}
              />
              <Widget
                icon={<FaEnvelope className="h-6 w-6" />}
                title={'Email'}
                subtitle={members[0]?.user?.email || 'N/A'}
              />
              <Widget
                icon={<FaCalendarAlt className="h-7 w-7" />}
                title={'Joined'}
                subtitle={formatDateTime(members[0]?.createdAt) || 'N/A'}
              />
            </div>
          )}

          {/* No memberships case */}
          {memberships.length === 0 ? (
            <div>
              <p className=" mt-14 dark:text-white">
                You are not a member of any organization.
              </p>
              {isModalOpen && (
                <CreateOrganization
                  onClose={closeModal}
                  onSuccess={refreshData}
                />
              )}
            </div>
          ) : (
            <>
              {/* Memberships list */}
              <OrganizationList />
            </>
          )}
        </>
      )}

      {/* Invite Acceptance */}
      <div className="mt-4">
        {isAuthenticated && inviteCode && (
          <AcceptInvite
            inviteCode={inviteCode}
            onInviteAccepted={handleInviteAccepted}
            onInviteRejected={handleInviteRejected}
          />
        )}

        {isAuthenticated && invites.length > 0 && (
          <div>
            {invites.map(invite => (
              <div key={invite.inviteCode} className="mt-4">
                <AcceptInvite
                  inviteCode={invite.inviteCode}
                  onInviteAccepted={handleInviteAccepted}
                  onInviteRejected={handleInviteRejected}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Organisation;
